import { createAsyncThunk } from "@reduxjs/toolkit";
import { setReferenceNo } from "../utility/orderUtils";
import { newRelic } from "../utility/newrelic";

export const submitOrderData = createAsyncThunk(
  "submitOrder",
  async (payload) => {
    try {
      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("MerchantToken", sessionStorage.getItem("merchantToken"));

      let raw = JSON.stringify({payment:payload?.payment});

      let requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      const url = `${sessionStorage.getItem("apiHost")}/mcx/${sessionStorage.getItem("merchantSlug")}/venue/${payload.slug}/v1/quotes/${payload?.reference_no}/finalize`;

      const response = await fetch(
        url,
        requestOptions
      );
      if (response.ok) {
        const data = await response.json();
        data.success = true;
        const ipAddress = await fetch('https://api.ipify.org?format=json')
          .then(response => response.json())
          .then(data => data.ip)
          .catch(error => {
            newRelic.noticeError(error);
            return 'Unable to fetch IP';
          });
        newRelic.log(`Ecomm_Menu - Submit Order Success ${payload.slug}`, { customAttributes: {response: JSON.stringify(data), url: url, options: requestOptions, ipAddress: ipAddress }});
        setReferenceNo(data?.quote?.venue_id, null);
        return data;
      }else{
        const error = await response.json();
        newRelic.log(`Ecomm_Menu - Submit Order Error ${payload.slug}`, { customAttributes: {response: JSON.stringify(error), url: url, options: requestOptions }});
        return {message: error?.errors, status: response.status, success: false};
        // throw new Error("Failed to fetch submit order");
      }
    } catch (error) {
      newRelic.noticeError(error);
      newRelic.log(`Ecomm_Menu - Submit Order Error ${payload.slug}`, { customAttributes: {response: JSON.stringify(error)}});
      return {message: error.message, success: false};
      // throw new Error(error.message);
    }
  }
);


