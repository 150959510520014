import { Flex, Modal, Typography, Input, Button } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getReferenceNo } from "../utility/orderUtils";
import { postPosabitPaySignin } from "../store/InitializeQuotesPostAPI";
import { notNull } from "../sharedFunctions";
import { fetchQuoteCartInfoApi } from "../store/GetQuoteAction";

const { Text } = Typography;


export default function OtpSubmitModal({ otpModalShow, setOtpModalShow, submitPhoneNumber, posabitPayPhone }) {
    const [otp, setOtp] = useState('');
    const quote = useSelector((store) => store?.quote?.info?.quote);
    const inputRef = useRef(null);
    const dispatch = useDispatch();
    const venue_id = useSelector((store) => store?.venue?.info?.venue?.venue_id);
    const slugId = useSelector((store) => store?.venue?.info?.venue?.slug);
    const reference_no = getReferenceNo(venue_id);

    const otpSubmit = () => {
      dispatch(
        postPosabitPaySignin({
          slug: slugId,
          reference_no,
          customer: {
            telephone: posabitPayPhone,
            otp: otp
          },
        })
      );
      // if(isMobile){window.scrollTo(0, document.body.scrollHeight)}
    }

    useEffect(() => {
      if(quote?.payment?.posabit_pay_authorized){
        setOtpModalShow(false)
      }
    },[quote, setOtpModalShow])

    useEffect(() => {
      if (otpModalShow) {
        inputRef.current.focus();
      }
    }, [otpModalShow]);


  return (
    <Modal
      title={<Flex justify='center'><img src="/images/POSaBIT Pay Logo Final - Horizontal-3.jpg" alt="pay" style={{width: 200}}/></Flex>}
      open={otpModalShow}
      footer={<Button block size='large' type='primary' onClick={otpSubmit}>Submit</Button>}
      onCancel={() => setOtpModalShow(false)}
      zIndex={1500}
      styles={{content: {padding: 50}}}
      width={350}
    >
      <Flex justify='center' style={{marginTop: 20, marginBottom: 20, textAlign: 'center'}}>
        <Text style={{textAlign: 'center', fontSize:12}}>Enter the 4 digit code set to your mobile device via SMS to verify your account</Text>
      </Flex>
      <Flex justify='center' style={{marginTop: 20, marginBottom: 0}}>
        <Input.OTP
          autoFocus
          size='large'
          length={4}
          value={otp}
          ref={inputRef}
          mask='•'
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              otpSubmit()
            }
          }}
          onChange={(e) => {
            setOtp(e)
          }}
          type='tel'
        />
      </Flex>
      <Flex justify='center' align='center' gap='small'>
        <Text type='secondary' style={{textAlign: 'center', fontSize: 11}}>
          Didn't receive the OTP?
        </Text>
        <Button
          type='link'
          style={{padding: 0, fontSize: 11}}
          onClick={submitPhoneNumber}
        >
          Resend Code
        </Button>
      </Flex>
    </Modal>
  );
}
