import React from "react"
import { createAsyncThunk } from "@reduxjs/toolkit";
import { newRelic } from "../utility/newrelic";
import { notNull } from "../sharedFunctions";

export const fetchMenuFeedApi = createAsyncThunk(
  "menuFeed/fetchInfo",
  async (payload, thunkAPI) => {
    const selectedVenue = sessionStorage.getItem("selectedVenue");
    const recache = notNull(payload?.recache) ? '?recache=true' : '';
    try {
      const url = `${sessionStorage.getItem("apiHost")}/mcx/${sessionStorage.getItem("merchantSlug")}/venue/${selectedVenue}/v1/menu_feeds/${sessionStorage.getItem("feedId")}/product_data${recache}`;
      const response = await fetch(url, {
        headers: {
          merchantToken: sessionStorage.getItem("merchantToken"),
          authorization: "Bearer 1vm1ax-tIxpocYteXD1FWA",
        },
      });

      if (!response.ok) {
        const info = await response.json();
        newRelic.log(`Ecomm_Menu - Product Data Error ${selectedVenue}`, { customAttributes: {response: JSON.stringify(info), url: url }});
        <h2>No products found</h2>
      }

      const data = await response.json();

      const dataWithQuantity = {
        ...data,
        product_data: {
          ...data.product_data,
          menu_items: data?.product_data?.menu_items.map((item) => {
            return {
              ...item, variants: item?.variants.map((va, i) => {
                if (i === 0) {
                  return { ...va, selected: true, quantity: 1, }
                } else {
                  return { ...va, selected: false, quantity: 1, }
                }
              }),
              brand:data?.product_data?.brands.filter(br=>{
               if(br?.id === item?.brand_id){
                return br
               }else{
                return null
               }
              })[0]?.name
            };
          }),
        },
      };
      return dataWithQuantity;
    } catch (error) {
      newRelic.log(`Ecomm_Menu - Product Data Error ${selectedVenue}`, {customAttributes: {response: JSON.stringify(error)}});
      throw new Error(error.message);
    }
  }
);
