import React, { useState } from "react";
import Search from "./Search";
import { useSelector } from "react-redux";
import {Badge, Button, Space} from 'antd'
// import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { useNavigate } from "react-router-dom";
import { selectMenuFeed } from "../store/slice/MenuFeedAPISlice";
import FilterModal from "./FilterModal.js";
import CartDrawer from "./CartDrawer.js";
import { parseFiltersfromURL } from "../utility/productUtils.js";


const NavBar = () => {
  const [showFilters, setShowFilters] = useState(false);
  const content = useSelector((state) => state.content?.contentData);
  const {menu} = content || {};

  const [open, setOpen] = useState(false);
  // const wishlistCount = useSelector((state) => state.wishlist?.length); // Get the wishlist count from the Redux store
  const items = useSelector(
    (store) => store?.quote?.info?.quote?.items
  );

  //Product Count
  const categoryProductCounts = {};
  const subcategoryProductCounts = {};
  const brandProductCounts = {};
  const strainProductCounts = {};
  const typeProductCounts = {};
  const terpeneProductCounts = {};
  const flavorsProductCounts = {};
  const effectsProductCounts = {};
  const displayWeightsCount = {};
  const menuFeedData = useSelector(selectMenuFeed);
  if (menuFeedData) {
    const { product_data } = menuFeedData;

    const { categories, brands, strains, product_types, terpenes, flavors, effects, menu_items } = product_data;

    if (categories) {
      categories.forEach(category => {
        category.subcategories.forEach(subcategory => {
          subcategoryProductCounts[subcategory.id] = 0;
        });
        categoryProductCounts[category.id] = 0;
      });
    }
    if (brands) {
      brands.forEach(brand => {
        brandProductCounts[brand.id] = 0;
      });
    }

    if (strains) {
      strains.forEach(strain => {
        strainProductCounts[strain.id] = 0;
      });
    }
    if (product_types) {
      product_types.forEach(type => {
        typeProductCounts[type.id] = 0;
      });
    }
    if (terpenes) {
      terpenes.forEach(terpene => {
        terpeneProductCounts[terpene.id] = 0;
      });
    }
    if (flavors) {
      flavors.forEach(flavor => {
        flavorsProductCounts[flavor.id] = 0;
      });
    }
    if (effects) {
      effects.forEach(effect => {
        effectsProductCounts[effect.id] = 0;
      });
    }

    // Count the products in each category, brand, and strain
    if (menu_items) {
      menu_items.forEach(item => {

        item.category_ids.forEach(categoryId => {
          categoryProductCounts[categoryId]++;
        });
        item.category_ids.forEach(categoryId => {
          const currentCategory = categories?.find(cat => cat.id === categoryId);
          if (currentCategory) {
            currentCategory.subcategories.forEach(subcategory => {
              if (subcategoryProductCounts[subcategory.id] !== undefined && item.subcategory_ids.includes(subcategory.id)) {
                subcategoryProductCounts[subcategory.id]++;
              }
            });
          }
        });
        const { variants } = item;
        if (variants) {
          variants.forEach((variant) => {
            const { display_weight: displayWeight } = variant;
            if (displayWeight) {
              if (!displayWeightsCount[displayWeight]) {
                displayWeightsCount[displayWeight] = 0;
              }
              displayWeightsCount[displayWeight]++;
            }
          });
        }
        if (item.brand_id) {
          brandProductCounts[item.brand_id]++;
        }

        if (item.strain_id) {
          strainProductCounts[item.strain_id]++;
        }
        if (item.product_type_id) {
          typeProductCounts[item.product_type_id]++;
        }
        if (item.terpene_ids) {
          item.terpene_ids.forEach(terpeneId => {
            if (terpeneProductCounts[terpeneId] !== undefined) {
              terpeneProductCounts[terpeneId]++;
            }
          });
        }
        if (item.flavor_ids) {
          item.flavor_ids.forEach(flavorId => {
            if (flavorsProductCounts[flavorId] !== undefined) {
              flavorsProductCounts[flavorId]++;
            }
          });
        }
        if (item.effect_ids) {
          item.effect_ids.forEach(effectId => {
            if (effectsProductCounts[effectId] !== undefined) {
              effectsProductCounts[effectId]++;
            }
          });
        }
      });
    } else {
      console.log('menu_items is undefined');
    }

  } else {
    console.log('menuFeedData is undefined');
  }
  // End Product Count
  // click outside modal

  // End click outside modal
  const navigate = useNavigate();

  const currentFilters = parseFiltersfromURL(content?.filters.map(f => f.filter_type)); // JSON.parse(sessionStorage.getItem('activeFilters'))
  let filterCount = Object.values(currentFilters).flat()?.length
  if (currentFilters['search']) {
    filterCount--;
  }

  return (
    <React.Fragment>
        <Space size='small'>
          <Button
            size='large'
            shape='circle'
            type="text"
            style={{color: `${menu?.primary_color}`}}
            icon={<i className='fa fa-home'/>}
            onClick={() => navigate("/")}
          />
          <Badge
          count={currentFilters && filterCount}
          style={{backgroundColor:  menu?.secondary_color}}
          offset={[-3,3]}
          >
          <Button
            size='large'
            style={{color: `${menu?.primary_color}`}}
            shape='circle'
            type="text"
            onClick={() => setShowFilters(true)}
            icon={<i className='fa fa-filter'/>}
          />
          </Badge>
          <Search />
        </Space>
        <FilterModal showModal={showFilters} setShowModal={setShowFilters} />
        {/* <form onSubmit={handleApplyFilters}> */}
        {/* <form onSubmit={() => console.log(activeFilters)}>
          <Cascader
            size="large"
            options={filterOptions()}
            style={{ width: 'auto', minWidth:'150px', borderRadius: '50px'}}
            placeholder={<div><span className='fa fa-search'/> Search</div>}
            showSearch={{ filter }}
            // onSearch={handleApplyFilters}
            dropdownMenuColumnStyle={{ width: 'auto', minWidth:'150px' }}
            onChange={handleFilterChange}
            defaultValue={activeFilters}
            // autoClearSearchValue={false}
          />
        </form>
          <Button
            type="primary"
            // htmlType="submit"
            size='large'
            onClick={() => setShowFilters(true)}
            icon={<i className='fa fa-sliders'/>}
          >
            Filter
          </Button> */}

        <CartDrawer open={open} setOpen={setOpen} />
        <Badge
          count={items?.reduce((acc, item) => acc + item.quantity, 0)}
          style={{backgroundColor:  menu?.secondary_color}}
          offset={[-3,3]}
        >
          <Button
            size='large'
            style={{color: `${menu?.primary_color}`}}
            shape='circle'
            type="text"
            onClick={() => setOpen(true)}
            icon={<i className='fa fa-cart-shopping' style={{
              color: `${menu?.primary_color}`
            }}/>}
          />
        </Badge>

    </React.Fragment>
  );
};

export default NavBar;
