import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { getReferenceNo } from '../../utility/orderUtils';
import { fetchQuoteCartInfoApi } from '../GetQuoteAction';
import { emptyQuote } from './quoteSlice.js';

// Define the async thunk to fetch data from the API
export const fetchVenueInfo = createAsyncThunk(
  'venue/fetchVenueInfo',
  async (payload, thunkAPI) => {
    const selectedVenue = payload || sessionStorage.getItem('selectedVenue');
    try {
      const response = await axios.get(`${sessionStorage.getItem("apiHost")}/mcx/${sessionStorage.getItem("merchantSlug")}/venue/${selectedVenue}/v1/info`, {
        headers: {
            merchantToken: sessionStorage.getItem("merchantToken"),
          },
      });

      if (response.data?.venue?.venue_id) {
        const reference_no = getReferenceNo(response.data?.venue?.venue_id);
        const payload = {reference_no: reference_no}
        if (reference_no) {
          thunkAPI.dispatch(fetchQuoteCartInfoApi(reference_no, payload))

        } else {
          thunkAPI.dispatch(emptyQuote());
        }
      } else {
        thunkAPI.dispatch(emptyQuote());
      }

      return response.data; // Return the fetched data
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// Create a slice for venue information
const venueSlice = createSlice({
  name: 'venue',
  initialState: {
    info: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchVenueInfo.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchVenueInfo.fulfilled, (state, action) => {
        state.loading = false;
        state.info = action.payload;
      })
      .addCase(fetchVenueInfo.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

// Export actions, reducer, and selectors
export const venueActions = venueSlice.actions;
export const selectVenueInfo = (state) => state.venue.info;
export const selectVenueLoading = (state) => state.venue.loading;
export const selectVenueError = (state) => state.venue.error;
export default venueSlice.reducer;
