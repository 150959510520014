import React from "react";
import { Link } from "react-router-dom";

const Logo = ({ fileUrl, alignment, logoLinkTo, size }) => {

  const heights = {
    small: 90,
    medium: 120,
    large: 180
  }
  const widths = {
    small: '50%',
    medium: '70%',
    large: '90%'
  }
  const logoHeight = heights[size] || "90px";
  const logoWidth = widths[size] || "90%";
  return (
    <div className="logo_image"
      style={{
        textAlign: alignment || "center",
        padding: "1% 2% 1% 2%",
      }}
    >
      <Link to={logoLinkTo === null ? "/" : logoLinkTo}>
        {fileUrl && <img src={fileUrl} alt='Logo' style={{maxHeight: logoHeight, maxWidth: logoWidth}}/> }
      </Link>
    </div>
  );
};

export default Logo;
