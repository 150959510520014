import { createSlice } from '@reduxjs/toolkit';
import { postInitializeQuotesApi, postPosabitPaySignin, putQuotesUpdateItemsApi } from '../InitializeQuotesPostAPI';
import { fetchQuoteCartInfoApi } from '../GetQuoteAction';
import { v4 as uuidv4 } from 'uuid';

const quotesSlice = createSlice({
  name: 'quote',
  initialState: {
    info: null,
    loading: false,
    error: null,
  },
  reducers: {
    emptyQuote: (state, action) => {
      state.info = null;
      localStorage.clear();
    },
    posabitPaySignIn: (state, action) => {
      state.info = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postInitializeQuotesApi.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(postInitializeQuotesApi.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.info = {
          ...action.payload,
          quote: {
            ...action.payload.quote,
            items: action.payload.quote.items.map((item) => {
              return { ...item, id: uuidv4() };
            }),
          },
        };
      })
      .addCase(postInitializeQuotesApi.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(postPosabitPaySignin.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(postPosabitPaySignin.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.info = {
          ...action.payload,
          quote: {
            ...action.payload.quote,
            payment: {
              requested_payment_method: action.payload.quote.payment.requested_payment_method,
              posabit_pay_authorized: action.payload.quote.payment.posabit_pay_authorized,
            },
          },
        };
      })
      .addCase(postPosabitPaySignin.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(putQuotesUpdateItemsApi.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(putQuotesUpdateItemsApi.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.info = {
          ...action.payload,
          quote: {
            ...action.payload.quote,
            items: action.payload.quote.items.map((item) => {
              return { ...item, id: uuidv4() };
            }),
          },
        };
      })
      .addCase(putQuotesUpdateItemsApi.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchQuoteCartInfoApi.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchQuoteCartInfoApi.fulfilled, (state, action) => {
        state.loading = false;
        state.info = action.payload;
        state.error = null;
      })
      .addCase(fetchQuoteCartInfoApi.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });

  }
})

export const { emptyQuote } = quotesSlice.actions;

export default quotesSlice.reducer;
