import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import Logo from "./Logo";
import StoreOpenTime from "./StoreOpenTime";
import StoreLocation from "./StoreLocation";
import MenuType from "./MenuType";
import * as Constant from "../Constants";
import { emptyInitializeQuotes } from "../store/slice/InitializeQuotesAPISlice";
import { ConfigProvider, Row, Col, Flex, Space } from "antd";
import { isMobile } from 'react-device-detect'

const MainHeader = () => {
  const dispatch = useDispatch();
  const menu = useSelector((state) => state.content?.contentData?.menu);
  const header = useSelector((state) => state.content?.contentData?.components.filter((c) => c.type === "heading")[0]);
  const [headers, setHeaders] = useState({})
  const handleVenueChange = useCallback((selectedVenueSlug) => {
    dispatch(emptyInitializeQuotes());
  }, [dispatch]);

  useEffect(() => {
    setHeaders((prevHeaders) => {
      let newHeaders = { ...prevHeaders };
      if(header?.display_store_locations){ newHeaders.store_location = <StoreLocation onVenueChange={handleVenueChange}/>}
      if(header?.display_menu_types){ newHeaders.menu_types = <MenuType display_menu_types={header?.display_menu_types}/>}
      if(header?.display_business_hours){ newHeaders.business_hours = <StoreOpenTime />}
      return newHeaders;
    });
  },[header, handleVenueChange]); // 'headers' is not needed in the dependency array

  const info = useSelector((state) => state.merchant?.info);

  if (!info) {
    return <div>{Constant.LOADING}</div>; // Handle loading state if info is not available yet
  }

  return (
    <div
      className="header"
      style={{
        background : `${header?.heading_color}` || "#fff",
        color: `${header?.text_color}` || "#000",
      }}
    >
      <Logo fileUrl={header?.logo?.url} alignment={header?.logo_alignment} logoLinkTo={header?.logo_link_to} size={header?.logo_size} />
      <div className=""
      style={{
        padding: "10px",
      }}
      >
        <ConfigProvider
          theme={{
            token: {
              colorText: `${header?.text_color}`,
              paddingSM: '5px',
            },
            components: {
              Select:{
                selectorBg: `${menu?.primary_color}`,
                selectArrowColor: `${menu?.primary_color}`,
                optionSelectedBg: 'none'

              }
            },
          }}
        >
          {
            Object.values(headers ).length === 3 &&
            <>
            {
              isMobile ?
              <>
              <Row>
                <Col span={12} />
                <Col span={12}>
                <Flex justify='flex-end'>
                  <StoreOpenTime />
                </Flex>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <StoreLocation onVenueChange={handleVenueChange} />
                </Col>
                <Col span={12}>
                  <Flex justify='flex-end'>
                    <MenuType display_menu_types={header?.display_menu_types} />
                  </Flex>
                </Col>
              </Row>
              </>
              :
              <Flex justify='space-between'>
                <Space>
                  <StoreLocation onVenueChange={handleVenueChange} />
                  <MenuType display_menu_types={header?.display_menu_types} />
                </Space>
                <StoreOpenTime />
              </Flex>
              }
            </>
          }
          {
            Object.values(headers).length === 2 &&
            <Flex justify='space-between'>
              {
                Object.values(headers).map((heading, index) => <React.Fragment key={index}>{heading}</React.Fragment>)
              }
            </Flex>
          }
          {
            Object.values(headers).length === 1 &&
            <React.Fragment>{Object.values(headers)[0]}</React.Fragment>
          }
        </ConfigProvider>
      </div>
    </div>
  );
};

export default MainHeader;


