import { createAsyncThunk } from "@reduxjs/toolkit";
import { setReferenceNo } from '../utility/orderUtils';
import { newRelic } from "../utility/newrelic";

const createHeaders = () => {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("MerchantToken", sessionStorage.getItem("merchantToken"));
  return headers;
};

export const postInitializeQuotesApi = createAsyncThunk("initializeQuotes/post", async (payload, { rejectWithValue }) => {

  try {
    let myHeaders = createHeaders();
    let raw = JSON.stringify(payload);

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    const url = `${sessionStorage.getItem("apiHost")}/mcx/${sessionStorage.getItem("merchantSlug")}/venue/${payload.slug}/v1/quotes`;

    const response = await fetch(
      url,
      requestOptions
    );

    if (!response.ok) {
      const info = await response.json();
      newRelic.log(`Ecomm_Menu - Initialize Quotes Error ${payload.slug}`, { customAttributes: {response: JSON.stringify(info), url: url , options: requestOptions}});
      return rejectWithValue(`Failed to fetch data: ${response.statusText}`);
    }

    const data = await response.json();
      setReferenceNo(data?.quote?.venue_id, data?.quote?.reference_no);
      return data;
  } catch (error) {
    newRelic.log(`Ecomm_Menu - Initialize Quotes Error ${payload.slug}`, { customAttributes: {response: JSON.stringify(error)}});
    return rejectWithValue(error.message);
  }
});

export const putQuotesUpdateItemsApi = createAsyncThunk("initializeQuotes/put", async (payload, { rejectWithValue }) => {

  try {
    let myHeaders = createHeaders();
    let raw = JSON.stringify(payload?.quote || {});
    let tail = 'items';
    if(payload?.quote?.customer || payload?.quote?.payment || payload?.quote?.fulfillment){tail = ''}

    const apiUrl = `${sessionStorage.getItem("apiHost")}/mcx/${sessionStorage.getItem("merchantSlug")}/venue/${payload.slug}/v1/quotes/${payload?.reference_no}/${tail}`;

    let requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    const response = await fetch(apiUrl, requestOptions);

    if (!response.ok) {
      const info = await response.json();
      newRelic.log(`Ecomm_Menu - Update Quotes Error ${payload.slug}`, { customAttributes: {response: JSON.stringify(info), url: apiUrl, options: requestOptions}});
      return rejectWithValue(`Failed to fetch data: ${response.statusText}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    newRelic.log(`Ecomm_Menu - Update Quotes Error ${payload.slug}`, { customAttributes: {response: JSON.stringify(error)}});
    return rejectWithValue(error.message);
  }
});

export const postPosabitPaySignin = createAsyncThunk("posabitPaySignin/post", async (payload, { rejectWithValue }) => {

  try {
    let myHeaders = createHeaders();
    let raw = JSON.stringify(payload?.customer);

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    const url = `${sessionStorage.getItem("apiHost")}/mcx/${sessionStorage.getItem("merchantSlug")}/venue/${payload.slug}/v1/quotes/${payload?.reference_no}/posabit_pay_signin`;

    const response = await fetch(
      url,
      requestOptions
    );

    if (!response.ok) {
      const info = await response.json();
      newRelic.log(`Ecomm_Menu - Posabit Pay Signin Error ${payload.slug}`, { customAttributes: {response: JSON.stringify(info), url: url , options: requestOptions}});
      console.log({info})
      if(info?.errors === "The username or password is incorrect.") {
        return rejectWithValue("Your PIN is incorrect. Please try again.");
      }
      return rejectWithValue(`Failed to fetch data: ${response.statusText}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.log({error})
    newRelic.log(`Ecomm_Menu - Posabit Pay Signin Error ${payload.slug}`, { customAttributes: {response: JSON.stringify(error)}});
    return rejectWithValue(error.message);
  }
});
