import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import MainHeader from "./MainHeader";
import NavBar from "./NavBar";
import { headerColor, secondaryColor } from '../store/slice/ContentSlice.js';

const Heading = () => {
  const headerColorValue = useSelector(headerColor);
  const secondaryColorValue = useSelector(secondaryColor);
  const [show, setShow] = useState(false);
  const header = useSelector((state) => state.content?.contentData?.components.filter((c) => c.type === "heading")[0]);
  document.documentElement.style.setProperty('--header-color', headerColorValue);
  document.documentElement.style.setProperty('--secondary-color', secondaryColorValue);

  useEffect(() => {
    if (header) {
      setShow(true);
    }
  }, [header])

  return (
    <>
      <header>
        { show &&
          <section id=""
          style={{
            background: `${header?.heading_color}`,
            paddingBottom: 0
            }}
          >
            <MainHeader />
          </section>
        }
      </header>
      <header
        className="sticky-top"
      >
        <section id="user_info">
          <NavBar />
        </section>
      </header>
    </>
  )
}

export default Heading;
