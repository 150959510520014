import { Space, Modal, Typography, Button, Flex } from 'antd';
import React from 'react'

const { Text } = Typography;

export default function DisabledPosabitPayModal({disabledModalShow, setDisabledModalShow}) {

  return(
    <Modal
      open={disabledModalShow}
      title={<Flex justify='center'><img src="/images/POSaBIT Pay Logo Final - Horizontal-3.jpg" alt="pay" style={{width: 200}}/></Flex>}
      onCancel={() => setDisabledModalShow(false)}
      zIndex={1500}
      styles={{content: {padding: 50}}}
      footer={null}
      width={350}
    >
      <Space direction='vertical' size='large' style={{marginTop: 20, textAlign: 'center'}}>
      <Text type='danger'>
        Looks like there is an issue with your account, please reach out to POSaBIT support at <Text type='danger' strong>(855) 767-2248</Text> to get it resolved.
      </Text>
      <Button block size='large' type='default' onClick={() => setDisabledModalShow(false)}>
        <Text type='secondary'>
          Cancel
        </Text>
      </Button>
      </Space>
    </Modal>
  )
}
