import React, { useState } from "react";
import { useSelector } from "react-redux";
// import { selectWishlist } from "../store/slice/wishlistSlice";
import DynamicProductCard from "./DynamicProductCard";
import LoadingCard from "./LoadingCard";
import { Swiper, SwiperSlide } from 'swiper/react';
import { isDesktop } from 'react-device-detect';


// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import 'swiper/swiper-bundle.css';
import 'swiper/css/navigation';


// import required modules
import { FreeMode, Pagination, Navigation, Virtual } from 'swiper/modules';
// import { primaryColor } from "../store/slice/ContentSlice";

const ProductSlider = ({products, ids}) => {
  const { loading, error } = useSelector((state) => state.menuFeed || {});
  const [shownProducts, setShownProducts] = useState(12);

  /* End Add to cart */

  return (
    <>
      <Swiper
        slidesPerView={5}
        spaceBetween={0}
        a11y={{
          enabled: true,
          prevSlideMessage: 'Previous slide',
          nextSlideMessage: 'Next slide',
        }}
        breakpoints={{
          100: {slidesPerView: 1.1},
          350: {slidesPerView: 1.3},
          490: {slidesPerView: 1.5},
          575: {slidesPerView: 1.7},
          636: {slidesPerView: 2.1},
          715: {slidesPerView: 2.5},
          900: {slidesPerView: 3.1},
          1030: {slidesPerView: 3.5},
          1170: {slidesPerView: 4.1},
          1360: {slidesPerView: 4.5},
          1460: {slidesPerView: 5.2},
        }}
        onReachEnd={() => setShownProducts(shownProducts + 8)}
        freeMode={true}
        // navigation={{ nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev' }}
        navigation={isDesktop}
        virtual={true}
        // pagination={{
        //   clickable: true,
        // }}
        modules={[FreeMode, Pagination, Navigation, Virtual]}
        // className="mySwiper"
      >
        {
          <>
            {products?.length > 0 && !loading && !error
            ? (
              products.slice(0,shownProducts)?.map((item, key) => {
                return (
                  <SwiperSlide key={item?.id}>
                    <DynamicProductCard product={item} key={item?.id} />
                  </SwiperSlide>
                );
              })
              )
            : (
              <>
                {
                  [...Array(12)].map((item, key) => {
                    return (
                      <SwiperSlide key={key}>
                        <LoadingCard />
                      </SwiperSlide>
                    )
                  })
                }
              </>
            )}
            {/* {
              isDesktop &&
              <>
                <div className="swiper-button-prev" style={{color: primaryColorValue}}/>
                <div className="swiper-button-next" style={{color: primaryColorValue}}/>
              </>
            } */}
          </>
        }
      </Swiper>
    </>
  );
};

export default ProductSlider;


