import { createSlice } from "@reduxjs/toolkit";
import { fetchMenuFeedApi } from "../MenuFeedaction";

const menuFeedSlice = createSlice({
  name: "menuFeedData",
  initialState: {
    info: null,
    loading: false,
    status: "idle",
    error: null,
    isMedicalOnly: false,
    selectedMenuType: "Recreational",
    searchQuery: "",
    sortOrder: "",
    selectedCategories:[]
  },
  reducers: {
    toggleMedicalOnly: (state) => {
      state.isMedicalOnly = !state.isMedicalOnly;
    },
    setSelectedMenuType: (state, action) => {
      state.selectedMenuType = action.payload;
    },
    setSearchQuery: (state, action) => {
      state.searchQuery = action.payload;
    },
    // ----------------------------------------------
    changeVariant: (state, action) => {
      const { productId, variantId } = action.payload;

      state.info = {
        ...state.info,
        product_data: {
          ...state.info.product_data,
          menu_items: state.info?.product_data?.menu_items.map((pItem) => {
            if (pItem.id === productId) {
              return {
                ...pItem,
                variants: pItem?.variants?.map((vt) => {
                  if (vt.id === variantId) {
                    return { ...vt, selected: true };
                  } else {
                    return { ...vt, selected: false };
                  }
                }),
              };
            } else {
              return pItem;
            }
          }),
        },
      };
    },

    changeQuantity: (state, action) => {
      const { variant } = action.payload;

      state.info = {
        ...state.info,
        product_data: {
          ...state.info.product_data,
          menu_items: state.info?.product_data?.menu_items.map((pItem) => {
            return {
              ...pItem,
              variants: pItem?.variants.map((vItem) => {
                if (
                  vItem.sku_id === variant.sku_id &&
                  vItem?.unit === variant.unit &&
                  vItem?.unit_type === variant.unit_type
                ) {
                  return {
                    ...vItem,
                    quantity: variant.quantity,
                  };
                } else {
                  return vItem;
                }
              }),
            };
          }),
        },
      };
    },

    incrementQuantity: (state, action) => {
      const { variant } = action.payload;

      state.info = {
        ...state.info,
        product_data: {
          ...state.info.product_data,
          menu_items: state.info?.product_data?.menu_items.map((pItem) => {
            return {
              ...pItem,
              variants: pItem?.variants.map((vItem) => {
                if (
                  vItem.sku_id === variant?.sku_id &&
                  vItem?.unit === variant.unit &&
                  vItem?.unit_type === variant.unit_type
                ) {
                  return {
                    ...vItem,
                    quantity:
                      vItem.quantity < vItem.quantity_on_hand
                        ? vItem.quantity + 1
                        : vItem.quantity,
                  };
                } else {
                  return vItem;
                }
              }),
            };
          }),
        },
      };
    },

    decrementQuantity: (state, action) => {
      const { variant } = action.payload;

      state.info = {
        ...state.info,
        product_data: {
          ...state.info.product_data,
          menu_items: state.info?.product_data?.menu_items.map((pItem) => {
            return {
              ...pItem,
              variants: pItem?.variants.map((vItem) => {
                if (
                  vItem.sku_id === variant?.sku_id &&
                  vItem?.unit === variant.unit &&
                  vItem?.unit_type === variant.unit_type
                ) {
                  return {
                    ...vItem,
                    quantity:
                      vItem.quantity > 1 ? vItem.quantity - 1 : vItem.quantity,
                  };
                } else {
                  return vItem;
                }
              }),
            };
          }),
        },
      };
    },
    // ----------------------------------------------------

    setPriceRange: (state, action) => {
      state.priceRange = action.payload;
    },
    setSelectedBrand: (state, action) => {
      state.selectedBrand = action.payload;
    },
    setSelectedCategories: (state, action) => {
      state.selectedCategories = action.payload;
    },
    setSelectedSubcategory: (state, action) => {
      state.selectedSubcategory = action.payload;
    },

    setSelectedType: (state, action) => {
      state.selectedType = action.payload;
    },
    setSelectedStrains: (state, action) => {
      state.selectedStrains = action.payload;
    },
    setSelectedEffects: (state, action) => {
      state.selectedEffects = action.payload;
    },
    setSelectedFlavors: (state, action) => {
      state.selectedFlavors = action.payload;
    },
    setSelectedTerpenes: (state, action) => {
      state.selectedTerpenes = action.payload;
    },
    setSortOrder: (state, action) => {
      state.sortOrder = action.payload;
    },
    setSelectedPopularity: (state, action) => {
      state.sortPopularity = action.payload;
    },
    setSelectedWeight: (state, action) => {
      state.selectedWeight = action.payload;
    },

  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMenuFeedApi.pending, (state) => {
        state.status = "loading";
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchMenuFeedApi.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
        state.error = null;
        state.info = action.payload;
      })
      .addCase(fetchMenuFeedApi.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const {
  toggleMedicalOnly,
  setSelectedMenuType,
  incrementQuantity,
  decrementQuantity,
  changeVariant,
  setPriceRange,
  setSelectedBrand,
  setSelectedCategories,
  setSelectedSubcategory,
  setSelectedType,
  setSelectedTerpenes,
  setSelectedFlavors,
  setSelectedEffects,
  setSelectedStrains,
  setSortOrder,
  setSelectedPopularity,
  setSelectedWeight,
  changeQuantity,
} = menuFeedSlice.actions;
export const selectPriceRange = (state) => state.menuFeed.priceRange;
export const selectSelectedWeight = (state) => state.menuFeed.selectedWeight;
export const selectSelectedBrand = (state) => state.menuFeed.selectedBrand;
export const selectSelectedCategories = (state) =>
  state.menuFeed.selectedCategories;
export const selectSelectedSubcategory = (state) =>
  state.menuFeed.selectedSubcategory;
export const selectSelectedType = (state) => state.menuFeed.selectedType;
export const selectSelectedTerpenes = (state) =>
  state.menuFeed.selectedTerpenes;
export const selectSelectedEffects = (state) => state.menuFeed.selectedEffects;
export const selectSelectedFlavors = (state) => state.menuFeed.selectedFlavors;
export const selectSelectedStrains = (state) => state.menuFeed.selectedStrains;
export const selectSortOrder = (state) => state.menuFeed.sortOrder;
export const selectPopularity = (state) => state.menuFeed.sortPopularity;
export const selectMenuFeed = (state) => state.menuFeed.info;
export const selectIsMedicalOnly = (state) => state.menuFeed.isMedicalOnly;
export const selectSelectedMenuType = (state) => state.menuFeed.selectedMenuType;
export const { setSearchQuery } = menuFeedSlice.actions;
export const selectSearchQuery = (state) => state.menuFeed.searchQuery;
export default menuFeedSlice.reducer;
