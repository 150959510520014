export const isEmpty = (obj) => {
  for (const prop in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, prop)) {
      return false;
    }
  }

  return true;
}

export const isMedMenu = () => {
  return sessionStorage.getItem("menuType") === "Medical";
}


export const priceCheck = (variant, discount, med_discount) => {
  let discountedTotal
  let selectedDiscount = discount
  let discount_type = discount?.coupon_type;
  let discount_amount = discount?.amount;
  let standard_price = variant.price_cents

  if(isMedMenu()){
    selectedDiscount = med_discount
    discount_type = med_discount?.coupon_type;
    discount_amount = med_discount?.amount;
    standard_price = variant.med_price_cents
  }

  // if(variant.price_cents !== variant.med_price_cents){debugger}

  if(discount_type === 'percent'){
    discountedTotal = (standard_price - (standard_price * discount_amount / 10000)) / 100
  } else if(discount_type === 'dollar') { discountedTotal = (standard_price - discount_amount) / 100 }
  else{ discountedTotal = discount_amount / 100}

  return {
    discountedTotal,
    selectedDiscount,
    standard_price
  }
}
