import { createSlice } from '@reduxjs/toolkit';

const menuTypeSlice = createSlice({
  name: 'menuType',
  initialState: {
    menuType: 'Recreational', // Default value
  },
  reducers: {
    toggleMenuType: (state) => {
      state.menuType = state.menuType === 'Recreational' ? 'Medical' : 'Recreational';
    },
    changeMenuType: (state, action) => {
      state.menuType = action.payload;
    },
  },
});

export const { toggleMenuType, changeMenuType } = menuTypeSlice.actions;
export const selectMenuType = (state) => state.menuType.menuType;
export default menuTypeSlice.reducer;
