import { Space, Modal, Typography, Button, Flex } from 'antd';
import React from 'react'
import { PROD_CORE_URL, PRODUCTION_URL, TEST_CORE_URL } from '../Constants';

const { Text } = Typography;

export default function ManageAccountModal({manageAccountShow, setManageAccountShow}) {
  let link = PROD_CORE_URL + '/Payments/PaymentSource';
  if (window.posabitmenuwidget.config.server_host !== PRODUCTION_URL){link = TEST_CORE_URL + '/Payments/PaymentSource';}

  return(
    <Modal
      open={manageAccountShow}
      title={<Flex justify='center'><img src="/images/POSaBIT Pay Logo Final - Horizontal-3.jpg" alt="pay" style={{width: 200}}/></Flex>}
      onCancel={() => setManageAccountShow(false)}
      zIndex={1500}
      styles={{content: {padding: 50}}}
      footer={null}
      width={350}
    >
      <Space direction='vertical'  style={{marginTop: 20, textAlign: 'center'}}>
      <Text type='danger'>
      Unable to connect to your bank, select <Text type='danger' strong>Manage Account</Text> to re-link your bank account.
      </Text>
      <Button block size='large' type='primary'
        onClick={() => {
          window.open(link, '_blank');
        }}
        >
        Manage Account
        </Button>
      <Button block size='large' type='default' onClick={() => setManageAccountShow(false)}>
        <Text type='secondary'>
          Cancel
        </Text>
      </Button>
      </Space>
    </Modal>
  )
}
