import React from 'react';
import { Card, Skeleton, Divider } from 'antd';

const LoadingCard = () => {
  return (
    <Card style={{width: 273}}>
      <Skeleton.Image active />
      <Divider />
      <Skeleton active />
      <Skeleton.Button active />
    </Card>
  );
}

export default LoadingCard;
